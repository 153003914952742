<template>
  <component :is="wrapperComponent">
    <div
      class="row csn-user-account csn-user-account-section"
      id="user-account"
    >
      <div class="col-md-12">
        <div class="container">
          <div class="casino-cashier csn-deposit">
            <h1>{{ t('deposit') }}</h1>

            <TabPanels
              :blockedStep="blockedTabStep"
              :initialStep="initialTabStep"
              :stepNumber="4"
              :ref="tabPanelRef"
              :reset="reset"
              :titleList="titleList"
              :backButtonList="backButtonList"
            >
              <template v-slot:step-1>
                <div
                  class="row csn-deposit-panel-body-1"
                  v-if="DEPOSIT_METHOD_DICTIONARY"
                >
                  <div
                    v-for="(payment, id) in DEPOSIT_METHOD_DICTIONARY"
                    :key="id"
                    class="provider-box col-md-2 csn-deposit-provider-box"
                    :data-payment-method-id="
                      DepositMethod.getPaymentMethodId(payment)
                    "
                    :data-payment-method-provider="
                      DepositMethod.getProviderName(payment)
                    "
                    :data-payment-method-name="
                      DepositMethod.getPaymentMethodName(payment)
                    "
                    :data-payment-method-currency="payment.currency"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div
                          class="payment-method-box"
                          @click="selectPaymentMethod(id)"
                        >
                          <img
                            :src="getLogoPath(payment.logo)"
                            :alt="DepositMethod.getPaymentMethodName(payment)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row limits">
                      <div class="col-md-3">{{ t('min') }}</div>
                      <div class="col-md-9 text-right">
                        {{ DepositMethod.getMinAmountLimit(payment) }}
                        {{ DepositMethod.getCurrency(payment) }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="line"></div>
                      </div>
                    </div>
                    <div class="row limits">
                      <div class="col-md-3">{{ t('max') }}</div>
                      <div class="col-md-9 text-right">
                        {{ DepositMethod.getMaxAmountLimit(payment) }}
                        {{ DepositMethod.getCurrency(payment) }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:step-2>
                <div>
                  <div class="csn-deposit-example-btn-container">
                    <button
                      v-for="(amount, index) in exampleAmountList"
                      :key="index"
                      @click="handleExampleAmountClick(amount)"
                      class="btn casino-btn csn-deposit-example-btn csn-dap-digit-btn"
                    >
                      {{ amount }}
                    </button>
                  </div>
                  <Message
                    :type="messageType"
                    :message="message"
                    class="csn-deposit-second-step-msg"
                  />
                  <Form :ref="formRef">
                    <div class="row-fluid">
                      <MoneyField
                        :type="inputType.TEXT"
                        :name="name.PAYMENT_AMOUNT"
                        v-model="formData[name.PAYMENT_AMOUNT]"
                        :validators="[
                          validator.required,
                          validator.meetsMin(moneyLimit.min),
                          validator.meetsMax(moneyLimit.max),
                        ]"
                        :serverError="serverError[name.PAYMENT_AMOUNT]"
                        :description="moneyFieldDescription"
                        :sideLabel="t('amount_to_deposit_euro')"
                      />
                      <TextField
                        :type="inputType.TEXT"
                        :name="name.BONUS_CODE"
                        :sideLabel="t('bonus_code')"
                        v-model="formData[name.BONUS_CODE]"
                        :serverError="serverError[name.BONUS_CODE]"
                        class="csn-bonus-code"
                      />
                    </div>
                    <div class="csn-deposit-step-2-submit-btn-container">
                      <button
                        id="depositForm"
                        class="btn casino-btn casino-btn-default casino-btn-main-menu csn-deposit-second-step-submit-btn"
                        @click="submitForm"
                        :disabled="isSubmitting"
                      >
                        <ButtonLoader v-if="isSubmitting" />
                        <span v-else>{{ t('deposit') }}</span>
                      </button>
                    </div>
                  </Form>
                </div>
              </template>

              <template v-slot:step-3>
                <Loader />
              </template>

              <template v-slot:step-4>
                <div>
                  <Loader v-if="awaitsResult" />
                  <Message :type="step4msg.type" :message="step4msg.text" />
                  <template v-if="!isDepositInfoEmpty">
                    <div class="cashier-summary">
                      <div class="row csn-deposit-fourth-step-row">
                        <div class="col-md-6">
                          {{ t('deposit_amount') }}
                        </div>
                        <div class="col-md-6">
                          {{ depositAmount }}
                        </div>
                      </div>
                      <div class="row csn-deposit-fourth-step-row">
                        <div class="col-md-6">
                          {{ t('deposit_method') }}
                        </div>
                        <div class="col-md-6">
                          {{ depositInfo.methodName }}
                        </div>
                      </div>
                      <div
                        class="row text-white csn-deposit-fourth-step-row"
                        v-if="isAddingDepositSuccessful"
                      >
                        <div class="col-md-6">
                          {{ t('total_available_balance') }}
                        </div>
                        <div class="col-md-6">
                          {{ balance }}
                        </div>
                      </div>
                    </div>
                    <div class="csn-deposit-step-4-btn-container">
                      <Route
                        class="btn casino-btn casino-btn-default casino-btn-main-menu"
                        :to="{ name: gameRouteName }"
                      >
                        {{ t('play_game') }}
                      </Route>
                    </div>
                  </template>
                </div>
              </template>
            </TabPanels>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapState } from 'vuex'
import {
  DEPOSIT,
  Module,
  DEPOSIT_METHOD_DICTIONARY,
  CASINO_INFO,
  RouteName,
  SLASH,
  Digit,
  EMPTY_STRING,
  EMPTY_OBJECT,
  WRAPPER,
  USER,
} from '@/constants'
import {
  isEmpty,
  formatNumberLocale as format,
  isNil,
  isEmptyOrNil,
  head,
  navigateTo,
  isPlainObject,
  load,
} from '@/helpers'
import { formMixin } from '@/mixins'
import { AuthApi } from '@/api'
import * as DepositMethod from '@/models/getters/depositMethod'
import * as CasinoInfo from '@/models/getters/casinoInfo'

const ADD_DEPOSIT_TAB_PANEL_REF = 'addDepositTabPanelRef'

const selectedPaymentMethodInitialValue = EMPTY_OBJECT
const depositInfoInitialValue = EMPTY_OBJECT
const step4MsgInitialValue = EMPTY_OBJECT

const name = {
  PAYMENT_AMOUNT: 'paymentAmount',
  BONUS_CODE: 'bonusCode',
}
const formData = {
  [name.PAYMENT_AMOUNT]: EMPTY_STRING,
  [name.BONUS_CODE]: EMPTY_STRING,
}
const depositStatusEnum = {
  SUCCESS: 'success',
  WAIT: 'wait',
}

export default {
  name: DEPOSIT,
  mixins: [formMixin],
  components: {
    TabPanels: () => import('@/components/TabPanels'),
    Route: () => import('@/components/Route'),
    Form: () => import('@/components/FormData'),
    MoneyField: () => import('@/components/MoneyField'),
    Loader: () => import('@/components/Loader'),
    Message: () => import('@/components/atoms/Message'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    User: () => import('@/views/User'),
    Wrapper: () => import('@/components/Wrapper'),
    TextField: () => import('@/components/TextField'),
  },
  data: () => ({
    initialTabStep: Digit.ONE,
    blockedTabStep: Digit.TWO,
    formData: { ...formData },
    step4msg: step4MsgInitialValue,
    exampleAmountList: null,
    selectedMethod: selectedPaymentMethodInitialValue,
    depositInfo: depositInfoInitialValue,
    logoImageList: [], // NOTE: mutable array
  }),
  computed: {
    ...mapState(Module.DEPOSIT_METHOD, [DEPOSIT_METHOD_DICTIONARY]),
    ...mapState(Module.CASINO_INFO, [CASINO_INFO]),
    t() {
      return this.$createComponentTranslator(DEPOSIT)
    },
    DepositMethod: () => DepositMethod,
    gameRouteName: () => RouteName.GAME,
    tabPanelRef: () => ADD_DEPOSIT_TAB_PANEL_REF,
    name: () => name,
    moneyFieldDescription() {
      const minAmountLimit = DepositMethod.getMinAmountLimit(
        this.selectedMethod,
      )
      const maxAmountLimit = DepositMethod.getMaxAmountLimit(
        this.selectedMethod,
      )
      const currency = DepositMethod.getCurrency(this.selectedMethod)

      const min = format({
        number: minAmountLimit,
        currency,
        needsCoins: false,
      })
      const max = format({
        number: maxAmountLimit,
        currency,
        needsCoins: false,
      })

      return `${this.t('min')}: ${min} / ${this.t('max')}: ${max}`
    },
    moneyLimit() {
      return {
        min:
          DepositMethod.getMinAmountLimit(this.selectedMethod) || Digit.NOUGHT,
        max:
          DepositMethod.getMaxAmountLimit(this.selectedMethod) || Digit.NOUGHT,
      }
    },
    depositAmount() {
      return isEmptyOrNil(this.depositInfo)
        ? EMPTY_STRING
        : format({
            number: this.depositInfo.paymentAmount,
            currency: this.depositInfo.currency,
          })
    },
    isAddingDepositSuccessful() {
      const status = this.$router.history.current.query?.status

      return status === depositStatusEnum.SUCCESS
    },
    isDepositInfoEmpty() {
      return isEmpty(this.depositInfo)
    },
    awaitsResult() {
      return isEmpty(this.step4msg) && this.isDepositInfoEmpty
    },
    titleList() {
      return [
        this.t('select_deposit_method'),
        this.t('deposit_amount'),
        this.t('deposit_details'),
        this.t('acknowledgement'),
      ]
    },
    backButtonList() {
      return [null, 1, null, 1]
    },
    wrapperComponent() {
      return this.isMobileScreen ? WRAPPER : USER
    },
    balance() {
      return CasinoInfo.getAccountBalance(this.CASINO_INFO) || Digit.NOUGHT
    },
  },
  methods: {
    getLogoPath(logoPath) {
      const splitPath = logoPath.split(SLASH)
      const fileName = splitPath[splitPath.length - Digit.ONE]

      return this.logoImageList.find((image) => image.fileName === fileName)
        ?.src
    },
    importAll(r) {
      r.keys().forEach((key) => {
        const imageObject = {
          src: r(key),
          fileName: key.split(SLASH)[Digit.ONE],
        }

        return this.logoImageList.push(imageObject)
      })
    },
    nextStep() {
      this.$refs[this.tabPanelRef].next()
    },
    selectPaymentMethod(methodId) {
      this.selectedMethod = this.DEPOSIT_METHOD_DICTIONARY[methodId]
      this.blockedTabStep = Digit.THREE

      this.$nextTick(this.nextStep)
    },
    async submitForm() {
      const { isValid } = this.$refs[this.formRef].getValidation()

      if (!isValid) {
        return
      }

      this.isSubmitting = true

      try {
        const request = {
          paymentMethodId: DepositMethod.getPaymentMethodId(
            this.selectedMethod,
          ),
          paymentMethodName: DepositMethod.getPaymentMethodName(
            this.selectedMethod,
          ),
          paymentProviderName: DepositMethod.getProviderName(
            this.selectedMethod,
          ),
          paymentMethodCurrency: DepositMethod.getCurrency(this.selectedMethod),
          paymentAmount: this.formData[this.name.PAYMENT_AMOUNT],
          bonusCode: this.formData[this.name.BONUS_CODE],
        }
        const response = await AuthApi.addDeposit(request)

        if (!this.validateResponse(response)) {
          return
        }

        const { data, state, msg } = response

        if (state === this.responseState.OK && data?.paymentLink) {
          this.blockedTabStep = Digit.FOUR
          window.location.href = data.paymentLink

          this.serverError = {}
          this.formDataSubmitCopy = {}
        }

        if (state === this.responseState.ERROR) {
          const serverError = {}
          this.message = msg
          this.messageType = this.messageTypeEnum.FAILURE

          Object.keys(data.errors || EMPTY_OBJECT).forEach((key) => {
            serverError[key] = data.errors[key].msg
          })

          this.serverError = serverError
          this.formDataSubmitCopy = { ...this.formData }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isSubmitting = false
      }
    },
    reset() {
      this.initialTabStep = Digit.ONE
      this.blockedTabStep = Digit.TWO

      navigateTo({ name: RouteName.DEPOSIT }, { isStrict: true })
    },
    handleExampleAmountClick(amount) {
      this.formData[this.name.PAYMENT_AMOUNT] = amount
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(currentFormData) {
        this.message && (this.message = null)
        this.messageType && (this.messageType = null)

        if (!Object.keys(this.serverError).length) {
          return
        }

        Object.keys(this.serverError).forEach((key) => {
          if (currentFormData[key] === this.formDataSubmitCopy[key]) {
            return
          }

          const newServerError = { ...this.serverError }
          delete newServerError[key]
          this.serverError = newServerError
        })
      },
    },
  },
  async created() {
    this.importAll(
      require.context(
        '../../assets/images/payment-method',
        true,
        /\.(png|jpg|jpeg|svg)$/,
      ),
    )
    const token = this.$router.history.current.query?.token
    const depositState = this.$router.history.current.query?.status
    const hasDepositState = !isNil(depositState)

    try {
      const [exampleAmount, depositDetails] = await load([
        AuthApi.getExampleAmount(), //NOTE: data for step 2
        hasDepositState && AuthApi.getDepositDetails({ token }), // NOTE: data for step 4
      ])

      if (
        isPlainObject(exampleAmount) &&
        exampleAmount.state === this.responseState.OK
      ) {
        this.exampleAmountList = exampleAmount.data.items.flat()
      }

      if (!hasDepositState) {
        return
      }

      if (!isPlainObject(depositDetails)) {
        return
      }

      const { data, state, msg } = depositDetails
      this.initialTabStep = Digit.FOUR
      this.blockedTabStep = null

      if (state === this.responseState.OK) {
        const {
          paymentAmount,
          methodName,
          currency,
          paymentHoldReason,
          state: stateMessage,
        } = head(data)

        this.depositInfo = { paymentAmount, methodName, currency }
        this.step4msg =
          depositState === depositStatusEnum.SUCCESS
            ? { type: this.messageTypeEnum.SUCCESS, text: stateMessage }
            : { type: this.messageTypeEnum.FAILURE, text: paymentHoldReason }
      }

      if (state === this.responseState.ERROR) {
        this.step4msg = { type: this.messageTypeEnum.FAILURE, text: msg }
      }
    } catch (e) {
      console.log(e)
    }
  },
  mounted() {
    if (this.$route?.params?.bonusCode) {
      this.formData = {
        ...this.formData,
        [name.BONUS_CODE]: this.$route.params.bonusCode,
      }
    }
  },
}
</script>
